import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Form,
  Image,
  Input,
  Typography,
  notification,
} from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UseAxios, encryptData } from "../../utils";
import { BASE_URL } from "../../config";
import { startLoading, stopLoading } from "../../redux/reducers/loaderReducer";
import LoginImage from "../../image/login.png";
import logo from "../../image/vkvmlogo-2.png";
import "./style.scss";
import ConfirmPopup from "../../custom/confirm-popup";

const { Password } = Input;

const Login = () => {
  const [form] = Form.useForm();
  const [isForgot, setIsForgot] = useState(false);
  const [isForgotConfirm, setIsForgotConfirm] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleIsForgot = () => {
    form.resetFields();
    setIsForgot(!isForgot);
  };

  const handleConfirmModal = () => setIsForgotConfirm(!isForgotConfirm);

  const handleForgotPassword = () => {
    handleConfirmModal();
    dispatch(startLoading());
    const { username, email } = form.getFieldsValue();

    UseAxios.post("forgot_password", { username, email })
      .then((response) => {
        notification.success({
          message: response.data.message,
        });
        handleIsForgot();
      })
      .catch((error) =>
        notification.error({
          message: error.response.data.message,
        })
      )
      .finally(() => dispatch(stopLoading()));
  };

  const handleLogin = (payload) => {
    dispatch(startLoading());
    const { username, password, is_pp_accepted } = payload;
    const encryptedPassword = encryptData(password);
    const loginPayload = {
      username,
      password: encryptedPassword,
      is_pp_accepted,
      login_url: `${BASE_URL}login`,
    };

    UseAxios.post("login", loginPayload)
      .then((response) => {
        const responseData = response.data.data || {};
        const { token, profile_details } = responseData;
        localStorage.setItem("userDetails", JSON.stringify(responseData));
        localStorage.setItem("account_status", JSON.stringify(profile_details));
        localStorage.setItem("accessToken", token.access);
        localStorage.setItem("refreshToken", token.refresh);
        navigate("/search");
      })
      .catch((error) =>
        notification.error({ message: error.response.data.message })
      )
      .finally(() => dispatch(stopLoading()));
  };

  const onFinish = (formValues) => {
    if (isForgot) {
      handleConfirmModal();
    } else {
      handleLogin(formValues);
    }
  };

  useEffect(() => localStorage.clear(), []);

  return (
    <>
      <Flex
        justify="center"
        align="center"
        gap="middle"
        className="login-container"
      >
        <Col xs={0} sm={0} md={0} lg={13} className="image-container">
          <Image src={LoginImage} preview={false} alt="login-img" />
        </Col>
        <Flex vertical gap="middle" className="form-container">
          <Typography.Title level={3}>
            <Image src={logo} alt="logo" width={64} preview={false} /> <br />
            Namma Kongu Vellala Gounder Matrimony <br />
            Welcomes You
          </Typography.Title>
          <Card bordered>
            <Form
              form={form}
              size="large"
              layout="vertical"
              onFinish={onFinish}
            >
              <Form.Item
                name="username"
                label={isForgot ? "Username" : "Username / Email"}
                rules={[
                  {
                    required: true,
                    message: `Please enter your ${
                      isForgot ? "username" : "username / email"
                    }`,
                  },
                ]}
              >
                <Input
                  placeholder={`Enter your ${
                    isForgot ? "username" : "username / email"
                  }`}
                />
              </Form.Item>
              {isForgot ? (
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email",
                    },
                  ]}
                >
                  <Input placeholder="Enter your email" />
                </Form.Item>
              ) : (
                <>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your password",
                      },
                    ]}
                  >
                    <Password placeholder="Enter your password" />
                  </Form.Item>
                  <Form.Item
                    name="is_pp_accepted"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  "Check the Privacy Policy & Terms and Conditions to proceed further."
                                )
                              ),
                      },
                    ]}
                    valuePropName="checked"
                  >
                    <Checkbox>
                      I agree to the{" "}
                      <a href="/privacy-policy" target="_blank">
                        Privacy Policy
                      </a>{" "}
                      and{" "}
                      <a href="/terms-and-conditions" target="_blank">
                        Terms and Conditions
                      </a>{" "}
                      of NKVG Matrimony.
                    </Checkbox>
                  </Form.Item>
                  <Col align="end" className="forgot-password">
                    <span onClick={handleIsForgot}>Forgot Password?</span>
                  </Col>
                </>
              )}
              <Flex vertical gap="small">
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                {isForgot && (
                  <Button danger type="primary" onClick={handleIsForgot}>
                    Go to Login
                  </Button>
                )}
              </Flex>
            </Form>
            {!isForgot && (
              <>
                <br />
                <Flex justify="center" gap="small">
                  <span>New to NKVG Matrimony?</span>
                  <NavLink className="register-link" to="/register">
                    Register Now
                  </NavLink>
                </Flex>
              </>
            )}
          </Card>
        </Flex>
      </Flex>
      {isForgotConfirm && (
        <ConfirmPopup
          title="Are you sure to reset your password?"
          open={isForgotConfirm}
          okText="Confirm"
          cancelText="Cancel"
          onOk={handleForgotPassword}
          onCancel={handleConfirmModal}
        />
      )}
    </>
  );
};

export default Login;
