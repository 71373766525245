import { useEffect, useState } from "react";
import {
  Table,
  Form,
  Col,
  Input,
  Button,
  Row,
  Select,
  notification,
  Pagination,
  Typography,
  Flex,
  Modal,
} from "antd";
import { useDispatch } from "react-redux";
import { UseAuthAxios } from "../../../utils";
import {
  startLoading,
  stopLoading,
} from "../../../redux/reducers/loaderReducer";
import CustomButton from "../../../custom/success-button";
import "./style.scss";

const { Option } = Select;

const ReportedAccount = () => {
  const dispatch = useDispatch();
  const authAxios = UseAuthAxios();

  const [searchForm] = Form.useForm();
  const [approvalForm] = Form.useForm();
  const [reportedAccounts, setReportedAccounts] = useState({
    accounts: [],
    pagination: {},
  });
  const [accountDetails, setAccountDetails] = useState({
    visible: false,
    data: null,
  });

  const reported_account_status = ["Reported", "Resolved"];
  const initialValues = { report_status: ["Reported"], profile_id: "" };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      render: (text) => `RA-${text}`,
    },
    {
      title: "Profile Id",
      dataIndex: "reporter_profile_id",
    },
    {
      title: "Profile Name",
      dataIndex: "reporter_profile_name",
    },
    {
      title: "Reported Profile ID",
      dataIndex: "reported_profile_id",
    },
    {
      title: "Reported Profile Name",
      dataIndex: "reported_profile_name",
    },
    {
      title: "Report",
      dataIndex: "report",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
    },
    {
      title: "Report Status",
      dataIndex: "is_report_resolved",
    },
    {
      title: "Admin Notes",
      dataIndex: "admin_notes",
    },
    {
      title: "Action",
      render: (data) => (
        <Flex gap={8} justify="center" wrap>
          {data.is_report_resolved === "Pending" && (
            <CustomButton
              success
              type="primary"
              onClick={() => handleOpenApproval(data)}
            >
              Process
            </CustomButton>
          )}
          <Button
            type="primary"
            onClick={() => {
              handleViewProfile(data?.reported_profile_id);
            }}
          >
            View Profile
          </Button>
        </Flex>
      ),
    },
  ];

  const handleOpenApproval = (data) => {
    setAccountDetails({
      visible: true,
      data,
    });
  };

  const handleCancelApproval = () => {
    approvalForm.resetFields();
    setAccountDetails({
      visible: false,
      data: null,
    });
  };

  const handleSubmitApproval = async ({ admin_notes }) => {
    handleCancelApproval();
    dispatch(startLoading());
    const { id: issue_id } = accountDetails.data;
    const payload = {
      issue_id,
      admin_notes,
    };
    try {
      const { data } = await authAxios.post(
        "process_reported_accounts",
        payload
      );
      const { accounts, pagination } = reportedAccounts;
      const updatedAccounts = accounts.map((account) =>
        account.id === issue_id
          ? { ...account, is_report_resolved: "Resolved" }
          : account
      );
      setReportedAccounts({ accounts: updatedAccounts, pagination });
      notification.success({ message: data.message });
    } catch (error) {
      notification.error({ message: error.response.data.message });
    } finally {
      dispatch(stopLoading());
    }
  };

  const fetchReportedAccounts = async (payload) => {
    dispatch(startLoading());
    try {
      const response = await authAxios.post("get_reported_accounts", payload);
      const { data: accounts, pagination_data: pagination } = response.data;
      setReportedAccounts({
        accounts,
        pagination,
      });
    } catch (error) {
      notification.error({ message: error.response.data.message });
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleSearchApproval = ({ report_status }) => {
    fetchReportedAccounts({
      report_status,
      page_number: 1,
      records_per_page: 10,
    });
  };

  const handleViewProfile = (profile_id) =>
    window.open(`/search/${profile_id}`, "_blank");

  const handlePageChange = (pageNo) => {
    const { report_status } = searchForm.getFieldsValue();
    const payload = {
      report_status,
      page_number: pageNo,
      records_per_page: 10,
    };
    fetchReportedAccounts(payload);
  };

  useEffect(() => {
    const payload = {
      report_status: ["Reported"],
      page_number: 1,
      records_per_page: 10,
    };
    fetchReportedAccounts(payload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Typography.Title level={3} className="page-title">
        Reported Accounts
      </Typography.Title>
      <Form
        autoComplete="off"
        className="reported-account-search"
        initialValues={initialValues}
        form={searchForm}
        onFinish={handleSearchApproval}
      >
        <Row align="middle" gutter={[16, 16]} justify="center">
          <Col xs={24} sm={24} md={18} lg={12} xl={10} xxl={8}>
            <Form.Item
              label="Reported Account Status"
              name="report_status"
              rules={[
                {
                  required: true,
                  message: "Please select profile  status",
                },
              ]}
            >
              <Select
                showSearch
                mode="multiple"
                placeholder="Select profile verification status"
              >
                {reported_account_status.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={18} lg={12} xl={10} xxl={8}>
            <Form.Item name="profile_id" label="Search Profile ID">
              <Input placeholder="Enter Profile ID" />
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      <br />
      <Table
        className="reported-account-table"
        columns={columns}
        dataSource={reportedAccounts.accounts}
        pagination={false}
        bordered
        scroll={{
          x: 920,
        }}
        rowKey="profile_username"
      />
      <br />
      <Pagination
        className="reported-account-pagination"
        total={reportedAccounts.pagination.total_objects}
        current={reportedAccounts.pagination.requested_page_number}
        onChange={handlePageChange}
      />
      {accountDetails.visible && (
        <Modal
          className="reported-account-approval-modal"
          closable={false}
          maskClosable={false}
          title="Reported Account Approval"
          open={accountDetails.visible}
          onCancel={handleCancelApproval}
          footer={null}
        >
          <p className="user-details">
            <strong>
              Profile ID: <span>{accountDetails.data.reported_profile_id}</span>
            </strong>
          </p>
          <p className="user-details">
            <strong>
              Name: <span>{accountDetails.data.reported_profile_name}</span>
            </strong>
          </p>
          <Form
            autoComplete="off"
            form={approvalForm}
            onFinish={handleSubmitApproval}
          >
            <Form.Item
              initialValue=""
              label="Admin Notes"
              name="admin_notes"
              rules={[
                {
                  required: true,
                  message: "Please enter the notes",
                },
              ]}
            >
              <Input.TextArea
                rows={5}
                count={{
                  show: true,
                }}
                maxLength={250}
                placeholder="Enter the notes"
              />
            </Form.Item>
            <Flex gap={8} justify="center">
              <Button htmlType="submit" type="primary">
                Submit
              </Button>
              <Button danger onClick={handleCancelApproval} type="primary">
                Cancel
              </Button>
            </Flex>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default ReportedAccount;
