import { Button, Flex, Modal } from "antd";

const ConfirmPopup = (props) => {
  const { title, open, onOk, onCancel, okText, cancelText, width } = props;

  return (
    <Modal
      centered
      className="confirm-popup"
      closeIcon={false}
      title={title}
      open={open}
      onCancel={onCancel}
      width={width || "auto"}
      footer={
        <Flex gap={8} justify="center">
          <Button onClick={onOk} type="primary">
            {okText}
          </Button>
          <Button danger onClick={onCancel} type="primary">
            {cancelText}
          </Button>
        </Flex>
      }
    />
  );
};

export default ConfirmPopup;
